import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Button, Container, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import InvoxyLogo_Full from "../images/logos/new-karmly/Karmly_Full.svg";

const NotFoundContainer = styled(Container)`
  background-color: ${(props) => props.theme.colours.white};
  min-height: 100vh;
  height: 100%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled(Box)`
  padding: 50px;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  box-shadow: ${(props) => props.theme.styles.shadow};
  background-color: ${(props) => props.theme.colours.white};
`;

const NotFoundPage = (props) => (
  <Layout fullPage>
    <Seo
      title="Oops... Page Not Found | 404"
      pathname={props.location.pathname}
    />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <NotFoundContainer>
      <Message stackGap={50} className="-textCenter">
        <img
          className="-center"
          src={InvoxyLogo_Full}
          alt="Karmly Logo"
          css={{ maxWidth: "220px" }}
        />
        <div>
          <h1>Are you lost?</h1>
          <h4>No need to panic, people get lost in the woods all the time.</h4>
        </div>
        <Button className="orange -lg -center" to="/recruitment">
          Let's Get Home
        </Button>
      </Message>
    </NotFoundContainer>
  </Layout>
);

export default NotFoundPage;
